import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import { useParams } from 'react-router-dom';
import DetailModal from "components/Modal/detail";
import JsPDF from 'jspdf';
import { CircularProgress, Backdrop } from '@mui/material';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {getGraderData, sendDataToUsers, getGeoReportDataForSpp, downlaodGraderPdf} from "../../utils/api";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import WaitingImage from "assets/images/small-logos/wating.svg";

function GraderForClient() {
  const [infoSB, setInfoSB] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [bc, setBc] = useState(null);
  const {account_id, location_id} = useParams();
  const {id} = useParams();
  const [location, setLocation] = useState('');
  const [logo, setLogo] = useState('');
  const [locationName, setLocationName] = useState('');
  const [locationAdress, setLocationAdress] = useState('');
  const [totalPro, setTotalPro] = useState(0);
  const [aiSummary, setAiSummary] = useState('');
  const [geoReport, setGeoReport] = useState(null);
  const [geoInformations, setgeoInformations] = useState(null);
  const [checkedCount, setCheckedCount] = useState({
    information: 0,
    design: 0,
    category: 0,
    review: 0,
    product: 0,
    qa: 0,
    post: 0,
    benchmarking: 0
  });
  const getData = async () => {
    setLoading(true);
    let res = await getGraderData({ id });
    setData(res?.data);
    setLocation(res?.locations[0]);
    setLocationName(res?.locations[0]?.title);
    setLocationAdress(res?.locations[0]?.storefrontAddress?.addressLines[0] ? res?.locations[0]?.storefrontAddress?.addressLines[0] : '');
    setLogo(res?.logo);
    setBc(res?.bc);
    setAiSummary(res?.ai_summary?.choices[0]?.message?.content);
    setGeoReport(res?.geo_report);
    setLoading(false);
  };
  const getGeoData = async () => {
    let res = await getGeoReportDataForSpp(geoReport);
    setgeoInformations(res);
  }
  useEffect(() => {
    if (geoReport) {
      getGeoData();
    }
  }, [geoReport]);
  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);
  const downloadPdf = async () => {
    setLoading(true);
    let dData = {
      grader: totalScore,
      analysis: aiSummary,
      logo: logo,
      bc: bc,
      business_name: locationName,
      address: locationAdress,
      checkedCount: checkedCount,
      data: data,
      geoInformations: geoInformations?.data
    }
    let res = await downlaodGraderPdf(dData);
    if (res?.status) {
      let base64 = res?.data
      let filename = res?.name

      // Decode base64 to binary data and convert it to a Blob
      const byteCharacters = atob(base64) // atob decodes base64 string
      const byteNumbers = new Array(byteCharacters.length)

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'application/pdf' })

      // Create a URL for the Blob and trigger the download
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${filename}.pdf` // Specify a name for the downloaded file
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Remove the link after download
    }
    setLoading(false);
  };
  const generatePDFToUsers = async (data) => {
    setLoading(true);
    setIsPrint(true);
    const report = new JsPDF({
        orientation: "p",
        unit: "pt",
        format: [980, 1800]
    });
    await report.html(document.querySelector('#pdf_form'), {margin: [0, 0, 0, 0]}).then(() => {
        const base64String = report.output('datauristring');
        console.log(base64String);
        sendDataToUsers({...data, location: locationName,  pdf: base64String });
    });
    setIsPrint(false);
    setOpenDetailModal(false);
    setLoading(false);
    setInfoSB(true);
    // console.log(data);
  };
  const closeInfoSB = () => setInfoSB(false);
  const [totalScore, setTotalScore] = useState(0);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [color, setColor] = useState('#D00000');
  const [isPrint, setIsPrint] = useState(false);
  const closeModal = () => {
    setOpenDetailModal(false);
  }
  useEffect(() => {
    setTotalPro((totalScore/29 *100).toFixed(2));
  }, [totalScore]);
  useEffect(() => {
    if (totalPro > 29 ) {
      setColor("#FAC300");
    }
    if (totalPro > 49) {
      setColor("#00D084");
    }
  }), [totalPro];
  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="GMB Gorilla"
      content="PDF was generated successfully."
      // dateTime="11 mins ago"
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const sumObjectValues = (obj) => {
    let sum = 0;
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        sum += sumObjectValues(obj[key]);
      } else {
        sum += parseInt(obj[key]) ? parseInt(obj[key]) : 0;
      }
    }
    return sum;
  };
  useEffect(() => {
    let information = 0;
    let design = 0;
    let category = 0;
    let review = 0;
    let product = 0;
    let qa = 0;
    let post = 0;
    let benchmarking = 0;
    for (let key in data) {
      if (key == 'business') {
        information = sumObjectValues(data.business);
      } else if (key == 'media') {
        design = sumObjectValues(data.media);
      } else if (key == 'category') {
        category = sumObjectValues(data.category);
      } else if (key == 'review') {
        review = sumObjectValues(data?.review);
      } else if (key == 'product') {
        product = sumObjectValues(data.product);
      } else if (key == 'qa') {
        qa = sumObjectValues(data.qa);
      } else if (key == 'post') {
        post = sumObjectValues(data.post);
      } else if (key == 'benchmarking') {
        benchmarking = sumObjectValues(data.benchmarking);
      }
    }
    setCheckedCount({
      ...checkedCount,
      information: information,
      design,
      category,
      review,
      product,
      qa,
      post,
      benchmarking
    })
  }, [data]);
  useEffect(() => {
    if (data) {
      let sum = sumObjectValues(data);
      setTotalScore(sum);
    }
  }, [data]);
  const sendPDFToUsers = () => {
    setOpenDetailModal(true);
  }
  const downloadCSV = () => {
    var csv_data_array = [
        [
          locationName
        ],
        [
          'Google Business Profile Audit 🌴',
            'SCORE'
        ],
        [
          'total',
          totalScore
        ],
        [
          'Total percent',
          (totalScore/29 *100).toFixed(2) + '%'
        ],
        [
          'Business Information'
        ],
        [
          'Business name',
          data?.business?.name ? 'TRUE' : 'FALSE'
        ],
        [
          'Business address',
          data?.business?.address ? 'TRUE' : 'FALSE'
        ],
        [
          'Business hours',
          data?.business?.hours ? 'TRUE' : 'FALSE'
        ],
        [
          'Business website',
          data?.business?.website ? 'TRUE' : 'FALSE'
        ],
        [
          'Business est date',
          data?.business?.est_date ? 'TRUE' : 'FALSE'
        ],
        [
          'Business description',
          data?.business?.description ? 'TRUE' : 'FALSE'
        ],
        [
          'Design'
        ],
        [
          'Profile avatar',
          data?.media?.avatar ? 'TRUE' : 'FALSE'
        ],
        [
          'Profile cover',
          data?.media?.cover ? 'TRUE' : 'FALSE'
        ],
        [
          'Exterior photos',
          data?.media?.exterior ? 'TRUE' : 'FALSE'
        ],
        [
          'Interior photos',
          data?.media?.exterior ? 'TRUE' : 'FALSE'
        ],
        [
          'Team photos',
          data?.media?.team ? 'TRUE' : 'FALSE'
        ],
        [
          'Videos',
          data?.media?.video ? 'TRUE' : 'FALSE'
        ],
        [
          'Product/service photos',
          data?.media?.product ? 'TRUE' : 'FALSE'
        ],
        [
          'Categorization'
        ],
        [
          'Primary category accurate for keyword',
          data?.category?.primary ? 'TRUE' : 'FALSE'
        ],
        [
          'Secondary categories accurate for keywords',
          data?.category?.secondary ? 'TRUE' : 'FALSE'
        ],
        [
          'Service areas set-up based on targeting',
          data?.category?.service_area ? 'TRUE' : 'FALSE'
        ],
        [
          'Reviews'
        ],
        [
          '31+ reviews',
          data?.review?.total ? 'TRUE' : 'FALSE'
        ],
        [
          '4.0 star rating',
          data?.review?.average ? 'TRUE' : 'FALSE'
        ],
        [
          '100% review responses',
          data?.review?.response ? 'TRUE' : 'FALSE'
        ],
        [
          'New review(s) within 30 days',
          data?.review?.newResponse ? 'TRUE' : 'FALSE'
        ],
        [
          'Products and Services'
        ],
        [
          'Product listings present',
          data?.product?.product ? 'TRUE' : 'FALSE'
        ],
        [
          'Services listings present',
          data?.product?.product ? 'TRUE' : 'FALSE'
        ],
        [
          'Q&A'
        ],
        [
          '10+ questions asked',
          data?.qa?.asked ? 'TRUE' : 'FALSE'
        ],
        [
          '100% questions answered',
          data?.qa?.answered ? 'TRUE' : 'FALSE'
        ],
        [
          'Posts'
        ],
        [
          'Recent post within past 30 days',
          data?.post?.recent ? 'TRUE' : 'FALSE'
        ],
        [
          'Tracking implemented',
          data?.post?.tracking ? 'TRUE' : 'FALSE'
        ],
        [
          'Benchmarking'
        ],
        [
          'Focus keyword in top 3',
          data?.benchmarking?.focus_keyword ? 'TRUE' : 'FALSE'
        ],
        [
          'Secondary keywords in top 10',
          data?.benchmarking?.second_keyword ? 'TRUE' : 'FALSE'
        ],
        [
          'Google Analytics implemented',
          data?.benchmarking?.anlytics ? 'TRUE' : 'FALSE'
        ],
    ];
    console.log(csv_data_array);
    let csvContent = ''
    csv_data_array.forEach(row => {
      csvContent += row.join(',') + '\n'
    });
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
    const objUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    // Passing the blob downloading url 
    link.setAttribute('href', objUrl);
    link.setAttribute('download', `${locationName}_grader.csv`);
    // Performing a download with click
    link.click();
  }
  const getHeaderColor = (count=0, total) => {
    let color = "#FFA8A8";
    if ((count/total) * 100 > 29 ) {
      color = "#FFE99C"
    }
    if ((count/total) * 100 > 49) {
      color = "#AFF0D8"
    }
    return color;
  }
  return (
    <DashboardLayout background="white">
      <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={loading}>
          <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Grid container className="main-page" sx={{ maxWidth: isPrint ? '980px' : '1440px' }} >
          <MDTypography sx={{ fontSize: '1.53rem', fontWeight: 500, color: '#00254d' }}>Google Business Profile Audit</MDTypography>
          <Grid item xs={12} lg={12} sx={{ mt: 4 }}>
            <Grid sx={{ background: '#F2F5FA' }}>
              <Grid data-html2canvas-ignore="true" sx={{ background: '#C9ECF3', padding: '40px', margin: 0, display: 'flex', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Grid>
                  {
                    logo && (
                      <img src={logo} width={180} alt="logo"/>
                    )
                  }
                </Grid>
                <Grid sx={{ display: 'flex' }}>
                  <Grid sx={{ marginRight: '16px' }}>
                    <div>
                      <input value={locationName} style={{ border: 'none', height: '40px', padding: '0 10px', color: 'gray', fontWeight: 500, width: 350 }}/>
                      <button onClick={sendPDFToUsers} style={{ borderRadius: '2px', width: '146px', height: '40px', background: '#04547C', color: 'white', border: 'none', marginTop: '2px', fontSize: '16px' }} disabled={!locationName} id="sendGrader" type="button" class="btn w-100 btn btn-outline-primary">Send</button>
                    </div>
                  </Grid>
                  <Grid sx={{ display: 'flex' }}>
                    <button id="downloadPdf" onClick={() => downloadPdf()} type="button" class="btn btn-outline-primary" style={{width: '180px', height: '40px', color: '#04547C', 'fontWeight': 700, border: '2px solid #04547C', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '2px' }}>
                        <FileDownloadIcon sx={{ width: '25px' }} />
                        Download PDF
                    </button>
                    <button id="downloadPdf" onClick={() => downloadCSV()} type="button" class="btn btn-outline-primary" style={{width: '180px', color: '#04547C', height: '40px', 'fontWeight': 700, border: '2px solid #04547C', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '16px', marginTop: '2px' }}>
                        <FileDownloadIcon sx={{ width: '25px' }} />
                        Download CSV
                    </button>
                  </Grid>
                </Grid>
              </Grid>
              <div id="pdf_form">
                <Grid data-html2canvas-ignore="true" sx={{padding: '20px 40px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', display: 'flex', alignItems: 'center', background: 'white' }}>
                  <Grid className="col-4">
                  </Grid>
                  <Grid className="col-4 text-center" sx={{ textAlign: 'center' }}>
                    <p data-html2canvas-ignore="true" id="beta-logo" className="text-center relative m-0" style={{fontSize: '42px', fontWeight: 700, color: '#1B1B42'}}>
                      Grader
                      <span style={{fontWeight: 700, fontSize: '12px', background: '#00D084', borderRadius: '24px', padding: '4px 10px', position: 'absolute', color: 'white' }}>
                          BETA
                      </span>
                    </p>
                  </Grid>
                  <Grid className="col-4">
                  </Grid>
                </Grid>
                <Grid container sx={{ px: 4, pt: 4, py: 6 }} >
                {isPrint && (
                    <Grid item md={12} className="printRegion">
                      {
                        logo && (
                          <img src={logo} width={180} alt="logo"/>
                        )
                      }
                    </Grid>
                  )}
                  <Grid sx={{ display: 'flex', justifyContent: 'center', width: '100%', my: 3 }}>
                    <p style={{fontSize: '16px', fontWeight: 400, textAlign: 'center', maxWidth: '1060px'}}>
                      {aiSummary}
                    </p>
                  </Grid>
                  {isPrint && (
                    <Grid item md={12} className="printRegion" sx={{ mt: 3 }}>
                      <div style={{ background: 'white', width: '300px', height: '200px', border: "1px solid #D9D9D9", padding: '16px 40px', borderRadius: '16px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <div style={{ textAlign: 'center' }}>
                          <p><strong style={{ fontSize: '16px', lineHeight: 1 }}>GBP Score</strong></p>
                          <p><strong style={{ fontSize: '32px', lineHeight: 1, marginTop: 10 }}>{totalScore}</strong></p>
                          <p><strong style={{ fontSize: '24px', lineHeight: 1, marginTop: 8, color: color}}>{totalPro}%</strong></p>
                        </div>
                      </div>
                    </Grid>
                  )}
                  <Grid data-html2canvas-ignore="true" px={3} item md={4} className="grader-block">
                    <Grid sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', mt: 4 }}>
                      <Grid sx={{ minWidth: '300px', maxWidth: '400px'}}>
                        <div className="pie" style={{ '--p': totalPro,  '--c': '#048CBC', '--b': '10px' }}>
                          <strong style={{ fontSize: '16px', lineHeight: 1 }}>GBP Score</strong>
                          <strong style={{ fontSize: '32px', lineHeight: 1, marginTop: 10 }}>{totalScore}</strong>
                          <strong style={{ fontSize: '24px', lineHeight: 1, marginTop: 8, color: color}}>{totalPro}%</strong>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid px={3} item md={ isPrint ? 6 : 4 } className="grader-block">
                    <p class="grader-header" style={{background: getHeaderColor(checkedCount?.information, 6)}}>Business Information ({checkedCount?.information}/6)</p>
                    <div className="table-region">
                      <table class="grader-table">
                          <tbody>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="name" type="checkbox" checked={data?.business?.name} />
                                  </td>
                                  <td class="grader-table-label">
                                    Business name 
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="address" type="checkbox" checked={data?.business?.address} />
                                  </td>
                                  <td class="grader-table-label">
                                    Business address 
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="hours" type="checkbox" checked={data?.business?.hours} />
                                  </td>
                                  <td class="grader-table-label">
                                    Business hours
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="website" type="checkbox" checked={data?.business?.website} />
                                  </td>
                                  <td class="grader-table-label">
                                    Business website
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="est_date" type="checkbox" checked={data?.business?.est_date}/>
                                  </td>
                                  <td class="grader-table-label">
                                    Business est date
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="description" type="checkbox" checked={data?.business?.description} />
                                  </td>
                                  <td class="grader-table-label">
                                    Business description
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                    </div>
                  </Grid>
                  <Grid px={3} item md={ isPrint ? 6 : 4 } className="grader-block">
                    <p class="grader-header" style={{background: getHeaderColor(checkedCount?.design, 7)}}>Design ({checkedCount?.design}/7)</p>
                    <div className="table-region">
                      <table class="grader-table">
                          <tbody>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="avatar" type="checkbox" checked={data?.media?.avatar} />
                                  </td>
                                  <td class="grader-table-label">
                                    Profile avatar
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="cover" type="checkbox" checked={data?.media?.cover} />
                                  </td>
                                  <td class="grader-table-label">
                                    Profile cover 
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="exterior" type="checkbox" checked={data?.media?.exterior} />
                                  </td>
                                  <td class="grader-table-label">
                                    Exterior photos
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="interior" type="checkbox" checked={data?.media?.interior} />
                                  </td>
                                  <td class="grader-table-label">
                                    Interior photos
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="team" type="checkbox" checked={data?.media?.team} />
                                  </td>
                                  <td class="grader-table-label">
                                    Team photos
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="video" type="checkbox" checked={data?.media?.video} />
                                  </td>
                                  <td class="grader-table-label">
                                    Videos
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox" style={{ borderBottom: 'none'}}>
                                      <input id="product" type="checkbox" checked={data?.media?.product} />
                                  </td>
                                  <td class="grader-table-label" style={{ borderBottom: 'none'}}>
                                    Product/service photos
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                    </div>
                  </Grid>
                  <Grid px={3} item md={ isPrint ? 6 : 4 } className="grader-block">
                    <p class="grader-header" style={{background: getHeaderColor(checkedCount?.category, 3)}}>Categorization ({checkedCount?.category}/3)</p>
                    <div className="table-region">
                      <table class="grader-table">
                          <tbody>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="primary" type="checkbox" checked={data?.category?.primary} />
                                  </td>
                                  <td class="grader-table-label">
                                    Primary category accurate for keyword
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="secondary" type="checkbox" checked={data?.category?.secondary} />
                                  </td>
                                  <td class="grader-table-label">
                                    Secondary categories accurate for keywords
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="service_area" type="checkbox" checked={data?.category?.service_area} />
                                  </td>
                                  <td class="grader-table-label">
                                    Service areas set-up based on targeting
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                    </div>
                  </Grid>
                  <Grid px={3} item md={ isPrint ? 6 : 4 } className="grader-block">
                    <p class="grader-header" style={{background: getHeaderColor(checkedCount?.review, 4)}}>Reviews ({checkedCount?.review}/4)</p>
                    <div className="table-region">
                      <table class="grader-table">
                          <tbody>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="total" type="checkbox" checked={data?.review?.total} />
                                  </td>
                                  <td class="grader-table-label">
                                    31+ reviews
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="average" type="checkbox" checked={data?.review?.average}/>
                                  </td>
                                  <td class="grader-table-label">
                                    4.0 star rating
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="response" type="checkbox" checked={data?.review?.response} />
                                  </td>
                                  <td class="grader-table-label">
                                    100% review responses
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="newResponse" type="checkbox" checked={data?.review?.newResponse} />
                                  </td>
                                  <td class="grader-table-label">
                                    New review(s) within 30 days
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                    </div>
                  </Grid>
                  <Grid px={3} item md={ isPrint ? 6 : 4 } className="grader-block pagebreak">
                    <p class="grader-header" style={{background: getHeaderColor(checkedCount?.product, 2)}}>Products and Services ({checkedCount?.product}/2)</p>
                    <div className="table-region">
                      <table class="grader-table">
                          <tbody>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="product" type="checkbox"  checked={data?.product?.product} />
                                  </td>
                                  <td class="grader-table-label">
                                    Product listings present
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="service" type="checkbox" checked={data?.product?.product} />
                                  </td>
                                  <td class="grader-table-label">
                                    Services listings present
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                    </div>
                  </Grid>
                  <Grid px={3} item md={ isPrint ? 6 : 4 } className="grader-block pagebreak">
                    <p class="grader-header" style={{background: getHeaderColor(checkedCount?.qa, 2)}}>Q&amp;A ({checkedCount?.qa}/2)</p>
                    <div className="table-region">
                      <table class="grader-table">
                          <tbody>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="asked" type="checkbox" checked={data?.qa?.asked} />
                                  </td>
                                  <td class="grader-table-label">
                                    10+ questions asked
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="answered" type="checkbox"  checked={data?.qa?.answered}  />
                                  </td>
                                  <td class="grader-table-label">
                                    100% questions answered
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                    </div>
                  </Grid>
                  <Grid px={3} item md={ isPrint ? 6 : 4 } className="grader-block">
                    <p class="grader-header" style={{background: getHeaderColor(checkedCount?.post, 2)}}>Posts ({checkedCount?.post}/2)</p>
                    <div className="table-region">
                      <table class="grader-table">
                          <tbody>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="recent" type="checkbox" checked={data?.post?.recent} />
                                  </td>
                                  <td class="grader-table-label">
                                    Recent post within past 30 days
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="tracking" type="checkbox" checked={data?.post?.tracking} />
                                  </td>
                                  <td class="grader-table-label">
                                    Tracking implemented
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                    </div>
                  </Grid>
                  <Grid px={3} item md={ isPrint ? 6 : 4 } className="grader-block">
                    <p class="grader-header" style={{background: getHeaderColor(checkedCount?.benchmarking, 3)}}>Benchmarking ({checkedCount?.benchmarking}/3)</p>
                    <div className="table-region">
                      <table class="grader-table">
                          <tbody>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="focus_keyword" type="checkbox" checked={data?.benchmarking?.focus_keyword} />
                                  </td>
                                  <td class="grader-table-label">
                                    Focus keyword in top 3
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="second_keyword" type="checkbox" checked={data?.benchmarking?.second_keyword} />
                                  </td>
                                  <td class="grader-table-label">
                                    Secondary keywords in top 10
                                  </td>
                              </tr>
                              <tr>
                                  <td class="grader-table-checkbox">
                                      <input id="anlytics" type="checkbox" checked={data?.benchmarking?.anlytics} />
                                  </td>
                                  <td class="grader-table-label">
                                    Google Analytics implemented
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                    </div>
                  </Grid>
                  {bc && (
                    <Grid px={3} sx={{ width: '100%', mt: 5 }}>
                      <p style={{textAlign: 'center', fontSize: '20px', fontWeight: 700 }}>Link Metrics</p>
                      <Grid sx={{ mt: 2, background: 'white', borderRadius: '10px', py: 2 }} container>
                        <Grid px={3} item md={ 4 }>
                          <p style={{ fontSize: '16px', fontWeight: 700, textAlign: 'center' }}>Domain Rating (DR)</p>
                          <p style={{ fontSize: '42px', fontWeight: 700, textAlign: 'center' }}>{bc?.dr}</p>
                        </Grid>
                        <Grid px={3} item md={ 4 }>
                          <p style={{ fontSize: '16px', fontWeight: 700, textAlign: 'center' }}>Referring Domains</p>
                          <p style={{ fontSize: '42px', fontWeight: 700, textAlign: 'center' }}>{bc?.refdomains}</p>
                        </Grid>
                        <Grid px={3} item md={ 4 }>
                          <p style={{ fontSize: '16px', fontWeight: 700, textAlign: 'center' }}>Backlinks</p>
                          <p style={{ fontSize: '42px', fontWeight: 700, textAlign: 'center' }}>{bc?.backlinks}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid px={3} sx={{ width: '100%', mt: 5 }}>
                    <p style={{textAlign: 'center', fontSize: '20px', fontWeight: 700 }}>Keyword Rankings</p>
                      {geoInformations ? (
                        <Grid>
                          {geoInformations?.status === true ? (
                            <Grid>
                              {
                                geoInformations?.key == "exist" ? (
                                  <Grid sx={{ mt: 2, background: 'white', borderRadius: '10px', py: 2 }} container>
                                    {geoInformations?.data?.map((ele, key) => {
                                      return (
                                        <Grid px={4} item md={ isPrint ? 6 : 4 } style={{ textAlign: 'center', marginTop: '10px' }}>
                                          <span style={{ fontSize: '16px', fontWeight: 700, textAlign: 'center', background: '#C9ECF3', padding: '5px', borderRadius: '2px' }}>{ele?.keyword}</span>
                                          <p style={{ fontSize: '42px', fontWeight: 700, textAlign: 'center' }}>#{ Math.round(ele?.arp) }</p>
                                          <div style={{ width: '100%', borderTop: '1px solid #D9D9D9', height: 1 }} />
                                          <p style={{ fontSize: '16px', fontWeight: 400, marginTop: 10 }}>Average Rank Position (ARP): &nbsp; <span style={{ fontWeight: 700 }}>{ele?.arp}</span></p>
                                          <p style={{ fontSize: '16px', fontWeight: 400 }}>Average Total Rank Position (ATRP): &nbsp; <span style={{ fontWeight: 700 }}>{ele?.atrp}</span></p>
                                          <p style={{ fontSize: '16px', fontWeight: 400 }}>Share of Local Voice (SoLV): &nbsp; <span style={{ fontWeight: 700 }}>{ele?.solv}</span></p>
                                        </Grid>
                                      )
                                    })}
                                  </Grid>
                                ) : (
                                  <Grid sx={{ mt: 2, py: 2, textAlign: 'center' }}>
                                    <img src={WaitingImage} />
                                    <Grid>
                                      <p style={{ fontSize: '16px', fontWeight: 700, marginTop:  '10px' }}>Splitting bananas and coconuts is much easier than grabbing your rankings.</p>
                                      <p style={{ fontSize: '16px', fontWeight: 400 }}>Please check back in a few minutes for your keyword rankings.</p>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </Grid>
                          ) : (
                            <Grid sx={{textAlign: 'center'}}>
                              <Grid>
                                  <img src={WaitingImage} />
                                  <Grid>
                                    <p style={{ fontSize: '16px', fontWeight: 700, marginTop:  '10px' }}>{geoInformations?.message}</p>
                                  </Grid>
                                </Grid>
                            </Grid>
                          )}
                        </Grid>
                      ) : (
                        <Grid sx={{ p: 4, textAlign: 'center' }} item md={12}>
                          <Grid sx={{textAlign: 'center'}}>
                            <img src={WaitingImage} />
                            <Grid>
                              <p style={{ fontSize: '16px', fontWeight: 700, marginTop:  '10px' }}>Splitting bananas and coconuts is much easier than grabbing your rankings.</p>
                              <p style={{ fontSize: '16px', fontWeight: 400 }}>Please check back in a few minutes for your keyword rankings.</p>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                </Grid>
              </div>
              {renderInfoSB}
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <DetailModal 
        openModal={openDetailModal}
        closeModal={() => closeModal()}
        sendData={(data) => {generatePDFToUsers(data)}}
        name={`${locationName}_grader.pdf`}
      />
    </DashboardLayout>
  );
}

export default GraderForClient;
