import axios from './axios';
import googleAxios from 'axios';

const  login =  async (data) => {
  try {
    let res = await axios.post('/admin_login', data);
    if(res.data.success) {
      localStorage.setItem('accessToken', res.data.token);
      axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
    }
    return res.data;
  } catch (error) {
    return 0;
  }
}

const createCustomer = async (data) => {
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    let res = await axios.post('/create_customer', data, { headers: { 'Authorization': `Bearer ${accessToken}` }});
    return res.data;
  } catch (error) {
    return 0;
  }
}


const createEquipment = async (data) => {
  try {
    let res = await axios.post('/getLocationForGrader', data);
    return res.data;
  } catch (error) {
    return 0;
  }
}

const getGraderData = async (data) => {
  try {
    let res = await axios.post('/api/getLocationForGrader', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' }});
    return res.data;
  } catch (error) {
    return null;
  }
}

const getLocationDataById = async (data) => {
  try {
    let res = await axios.post('/api/getLocationByPlaceId', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' }});
    return res.data;
  } catch (error) {
    return null;
  }
}
const sendDataToUsers = async (data) => {
  try {
    let res = await axios.post('/api/sendDataToUsers', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' }});
    return res.data;
  } catch (error) {
    return null;
  }
}
const createUser = async (data) => {
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    let res = await axios.post('/create_user', JSON.stringify(data), { headers: { 'Authorization': `Bearer ${accessToken}` }});
    return res.data;
  } catch (error) {
    return 0;
  }
}


const submitForm = async (data) => {
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    let res = await axios.post('/form_submit', data, { headers: { 'Authorization': `Bearer ${accessToken}` }});
    return res.data;
  } catch (error) {
    return 0;
  }
}

const removedCustomer = async (id) => {
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    let res = await axios.post('/delete_customer', {id: id}, { headers: { 'Authorization': `Bearer ${accessToken}` }});
    return res.data;
  } catch (error) {
    return 0;
  }
}


const removedEquipment = async (id) => {
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    let res = await axios.post('/delete_equipment', {id: id}, { headers: { 'Authorization': `Bearer ${accessToken}` }});
    return res.data;
  } catch (error) {
    return 0;
  }
}



const removedUser = async (id) => {
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    let res = await axios.post('/delete_user', {id: id}, { headers: { 'Authorization': `Bearer ${accessToken}` }});
    return res.data;
  } catch (error) {
    return 0;
  }
}

export function logout() {
  localStorage.setItem('accessToken', null);
  return true
}

const getMe = async () => {
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    let res = await axios.post('/getProfile', {}, { headers: { 'Authorization': `Bearer ${accessToken}` }});
    return res.data;
  } catch (error) {
    return 0;
  }
}
const getCustomers = async () => {
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    let res = await axios.get('/get_customers', { headers: { 'Authorization': `Bearer ${accessToken}` }});
    return res.data;
  } catch (error) {
    return 0;
  }
}

const getEndpointById = async (data) => {
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    let res = await axios.post('/get_equipment_by_id', data, { headers: { 'Authorization': `Bearer ${accessToken}` }});
    return res.data;
  } catch (error) {
    return 0;
  }
}

const get_form_by_equipment_id = async (data) => {
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    let res = await axios.post('/get_form_by_equipment_id', data, { headers: { 'Authorization': `Bearer ${accessToken}` }});
    return res.data;
  } catch (error) {
    return 0;
  }
}

const getEquipments = async (data) => {
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    let res = await axios.post('/get_equipment_by_customer', data, { headers: { 'Authorization': `Bearer ${accessToken}` }});
    return res.data;
  } catch (error) {
    return 0;
  }
}


const getUsers = async () => {
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    let res = await axios.get('/get_users', { headers: { 'Authorization': `Bearer ${accessToken}` }});
    return res.data;
  } catch (error) {
    return 0;
  }
}

const getGoogleAccounts = async (authToken, pageToken) => {
  try {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: pageToken ? `https://mybusinessaccountmanagement.googleapis.com/v1/accounts?pageToken=${pageToken}` : 'https://mybusinessaccountmanagement.googleapis.com/v1/accounts',
      headers: { 
        'Authorization': `Bearer ${authToken}`
      }
    };
    let res = await googleAxios.request(config);
    console.log('--------------------');
    console.log(res?.data);
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

const getGoogelLocations = async (aId, authToken) => {
  try {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://mybusinessbusinessinformation.googleapis.com/v1/${aId}/locations?pageSize=100&readMask=name,title,storefrontAddress`,
      headers: { 
        'Authorization': `Bearer ${authToken}`
      }
    };
    let res = await googleAxios.request(config);
    console.log('--------------------');
    console.log(res?.data);
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

const getGoogleGraderData = async (data) => {
  try {
    let res = await axios.post('/api/getGoogleGraderData', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' }});
    return res.data;
  } catch (error) {
    return null;
  }
}

const getGeoReportData = async (data) => {
  try {
    let res = await axios.post('/api/generateGeoData', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' }});
    return res.data;
  } catch (error) {
    return null;
  }
}

const downlaodGraderPdf = async (data) => {
  try {
    let res = await axios.post('/api/generateGraderPdf', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' }});
    return res.data;
  } catch (error) {
    return null;
  }
}

const getGeoReportDataForSpp = async (data) => {
  try {
    let res = await axios.post('/api/generateGeoDataForSpp', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' }});
    return res.data;
  } catch (error) {
    return null;
  }
}

export {
  login,
  getMe,
  getCustomers,
  createCustomer,
  removedCustomer,
  getUsers,
  createUser,
  removedUser,
  getEquipments,
  createEquipment,
  removedEquipment,
  getEndpointById,
  submitForm,
  get_form_by_equipment_id,
  getGraderData,
  getLocationDataById,
  sendDataToUsers,
  getGoogleAccounts,
  getGoogelLocations,
  getGoogleGraderData,
  getGeoReportData,
  getGeoReportDataForSpp,
  downlaodGraderPdf
}